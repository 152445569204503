import React from 'react';
import SettingsContainer from '../components/Pokemon Settings Components/SettingsContainer';

const SettingPage = () => {
  // console.log('setting', userData);
  return (
    <div>
      <div>
        <SettingsContainer />
      </div>
    </div>
  );
};

export default SettingPage;

import * as React from 'react';

const DailyGiftSVG = (props) => (
  <svg
    className="h-9 w-9 animate-shaking_brutally cursor-pointer duration-700 hover:scale-125 hover:animate-horizontal_shake hover:duration-700"
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 278.66 344.54"
    {...props}
  >
    <defs>
      <style>{'.cls-6{fill:#78bdc4}.cls-7{fill:#f8444f}'}</style>
    </defs>
    <path
      className="cls-6"
      d="M124.51 414.7a7.57 7.57 0 0 0 7.56 7.57h85.4V261.43h-93ZM110.67 191.6v51.89a8 8 0 0 0 8 8h98.8V183.6h-98.8a8 8 0 0 0-8 8ZM282.53 422.27h85.4a7.57 7.57 0 0 0 7.56-7.57V261.43h-93ZM381.33 183.6h-98.8v67.89h98.8a8 8 0 0 0 8-8V191.6a8 8 0 0 0-8-8Z"
      transform="translate(-110.67 -77.73)"
    />
    <path className="cls-7" d="M118.04 105.87h41.94v238.67h-41.94z" />
    <path
      className="cls-7"
      d="M303.33 160.09c27.95-10.86 43.31-23.08 46.95-37.37 2-7.81 1.23-19.85-13.74-32.85-16.17-14.05-29.57-13.39-38-10.36C267 90.9 255.82 154 254.65 161.15l-2.2 13.36 13.23-2.9a285.82 285.82 0 0 0 37.65-11.52Zm1.49-63.29a11 11 0 0 1 4-.75c3.32 0 8.46 1.41 15.69 7.69 6.34 5.51 9 10.37 8 14.44-.92 3.61-6.24 13.26-35.62 24.71-7.56 3-15 5.34-21.13 7.11 5.44-22 15.87-48.45 29.06-53.2ZM196.67 160.09a285.82 285.82 0 0 0 37.65 11.52l13.23 2.9-2.2-13.36C244.18 154 233 90.9 201.42 79.51c-8.39-3-21.79-3.69-38 10.36-15 13-15.73 25-13.74 32.85 3.68 14.28 19.04 26.51 46.99 37.37Zm-21.16-56.35c7.23-6.28 12.37-7.69 15.69-7.69a11 11 0 0 1 4 .75c13.19 4.75 23.62 31.16 29.09 53.2-6.1-1.77-13.57-4.16-21.13-7.11-29.38-11.45-34.7-21.1-35.61-24.71-1.06-4.07 1.63-8.93 7.96-14.44Z"
      transform="translate(-110.67 -77.73)"
    />
  </svg>
);

export default DailyGiftSVG;

import React from 'react';

function NotFoundPage() {
  return (
    <section>
      <h2>PAGE NOT FOUND</h2>
    </section>
  );
}

export default NotFoundPage;

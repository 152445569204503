const getCard = () => {
  return [
    { id: 'Item 1', imageUrl: '' },
    { id: 'Item 2', imageUrl: '' },
    { id: 'Item 3', imageUrl: '' },
    { id: 'Item 4', imageUrl: '' },
    { id: 'Item 5', imageUrl: '' },
    { id: 'Item 6', imageUrl: '' },
  ];
};

export { getCard };
